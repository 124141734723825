<template lang="html">
  <div class="container pt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Index' }">Панель</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Tags' }">Теги</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ $store.state.tag.name }}
        </li>
      </ol>
    </nav>
    <div class="d-flex">
      <h3 class="mb-3">{{ $store.state.tag.name }}</h3>
      <div class="ms-auto" v-if="$store.state.user.is_staff">
        <button
          v-if="tab === 'tags'"
          ref="tagModal"
          class="btn btn-secondary"
          data-bs-toggle="modal"
          data-bs-target="#tagModal"
          @click="$store.commit('mergeStore', {tagForm: {tag_id: $store.state.tag.id}})">
          Добавить тег
        </button>
      </div>
    </div>

    <ul class="nav nav-pills mb-4">
      <li
        v-for="t in tabs"
        :key="t.key"
        class="nav-item">
        <button
          class="nav-link"
          :class="{active: t.key === tab}"
          @click="tab = t.key">
          {{ t.name }}
        </button>
      </li>
    </ul>

    <div class="" v-if="$store.state.tag.id">

      <div v-if="tab === 'tags'" class="">
        <TagList />
      </div>

      <div v-if="tab === 'statistic'" class="">
        <Statistic />
      </div>

      <div v-if="tab === 'chats'" class="">
        <div class="d-flex flex-wrap">

          <div
            v-for="chat in $store.state.tag.chats"
            :key="chat.id"
            class="card card-body me-3 mb-3 position-relative">
            <h4 class="card-title">
              <router-link class="" :to="{name: 'Chat', params: {id: chat.id}}">
                {{ chat.telegram_name }}
              </router-link>
            </h4>
            <div class="text-white-50">
              <strong>{{ chat.name }}</strong> <small>{{ chat.desc }}</small>
            </div>
            <div class="d-flex mt-auto">
              <router-link class="btn btn-link ps-0 me-auto" :to="{name: 'Account', params: {id: chat.account.id}}">
                <strong>{{ chat.account.first_name }} {{ chat.account.last_name }} {{ chat.account.username }}</strong>
              </router-link>
            </div>
            <div class="position-absolute bottom-0 end-0 btn-group">
              <button
                class="btn btn-link"
                @click="$store.commit('mergeStore', { chat })"
                data-bs-toggle="modal"
                data-bs-target="#chatModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16">
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
              </button>
              <button
                class="btn btn-link"
                @click="deleteChat(chat)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </button>
            </div>
            <div class="position-absolute top-0 end-0 badge bg-success fs-12">
              Чат
            </div>
          </div>

        </div>
      </div>

      <div v-if="tab === 'form' && $store.state.user.is_staff" class="">
        <div class="row align-items-center justify-content-center">

          <div class="col-auto">
            <div class="card card-body mb-4">
              <h5 class="card-title mb-3">Форма</h5>
              <div class="mb-3">
                <!-- <label class="form-label">Название</label> -->
                <input
                  type="text"
                  v-model="$store.state.tag.name"
                  class="form-control"
                  placeholder="Название чата">
              </div>
              <button
                class="btn btn-primary me-3"
                @click="$socket.emit('tags', $store.state.tag)">
                Сохранить
              </button>
            </div>
          </div>

          <div class="col-auto">
            <div class="card card-body mb-4 pb-0">
              <h5 class="card-title mb-3">Пользователи</h5>
              <div class="">
                <div class="d-flex flex-wrap">
                  <UserItem
                    v-for="user in $store.state.tag.users"
                    :key="user.id"
                    :user="user"
                    class="bg-info text-dark"/>
                </div>

                <div class="d-flex flex-wrap">
                  <UserItem
                    v-for="user in $store.state.users.filter(u => !$store.state.tag.user_ids.includes(u.id))"
                    :key="user.id"
                    :user="user"
                    class="bg-secondary"/>
                </div>
              </div>
            </div>
          </div>

          <div class="col-auto">
            <div class="card card-body mb-4 pb-0">
              <h5 class="card-title mb-3">Чаты</h5>
              <div class="">
                <div class="d-flex flex-wrap">
                  <ChatItem
                    v-for="chat in $store.state.tag.chats"
                    :key="chat.id"
                    :chat="chat"
                    class="bg-success"/>
                </div>

                <div class="d-flex flex-wrap">
                  <ChatItem
                    v-for="chat in $store.state.chats.filter(c => !$store.state.tag.chat_ids.includes(c.id))"
                    :key="chat.id"
                    :chat="chat"
                    class="bg-secondary"/>
                </div>
              </div>
            </div>
          </div>

          <div class="col-auto">
            <div class="card card-body mb-4 pb-0">
              <h5 class="card-title mb-3">Лейблы</h5>
              <div class="d-flex flex-wrap">
                <LabelItem
                  v-for="label in $store.state.tag.labels"
                  :key="label.id"
                  :label="label"
                  class="bg-pink"/>
              </div>
              <div class="d-flex flex-wrap">
                <LabelItem
                  v-for="label in $store.state.labels.filter(c => !$store.state.tag.label_ids.includes(c.id))"
                  :key="label.id"
                  :label="label"
                  class="bg-secondary"/>
              </div>
            </div>
          </div>

          <div class="col-auto">
            <div class="card card-body mb-4 pb-0">
              <h5 class="card-title mb-3">Метрики</h5>

              <div class="d-flex flex-wrap">
                <div
                  v-for="metric in Object.entries(metrics).filter(m => $store.state.tag.metrics.includes(m[0]))"
                  :key="metric[0]"
                  @click="clickMetric(metric)"
                  class="card card-body me-3 mb-3 position-relative bg-purple hover-pointer">
                  <h6 class="card-title">
                    {{ metric[1] }}
                  </h6>
                </div>
              </div>

              <div class="d-flex flex-wrap">
                <div
                  v-for="metric in Object.entries(metrics).filter(m => !$store.state.tag.metrics.includes(m[0]))"
                  :key="metric[0]"
                  @click="clickMetric(metric)"
                  class="card card-body me-3 mb-3 position-relative bg-secondary hover-pointer">
                  <h6 class="card-title">
                    {{ metric[1] }}
                  </h6>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>


<script>
import UserItem from '../components/UserItem'
import TagList from '../components/TagList'
import ChatItem from '../components/ChatItem'
import LabelItem from '../components/LabelItem'
import Statistic from '../components/Statistic'
export default {
  name: 'Tag',
  components: {
    TagList,
    UserItem,
    ChatItem,
    LabelItem,
    Statistic,
  },
  data () {
    return {
      child: {},
      tab: 'statistic',
      tabs: [
        {
          name: 'Статистика',
          key: 'statistic',
        },
        {
          name: 'Чаты',
          key: 'chats',
        },
        {
          name: 'Теги',
          key: 'tags',
        },
        {
          name: 'Настройки',
          key: 'form',
        },
      ],
      metrics: {
        active_members: 'Активные пользователи',
        labels: 'Лейблы',
        labels_sentiments: 'Тональность лейблов',
        members: 'Пользователи',
        messages: 'Сообщения',
        messages_words: 'Слова в сообщениях',
        transactions: 'Трансакции',
        transactions_words: 'Слова в трансакциях',
        sentiments: 'Тональность сообщений',
        words: 'Слова',
        words_freq: 'Частые слова',
        // 'transaction',
        // 'transaction_words',
      }
    }
  },
  watch: {
    '$route.params.id' (n) {
      this.id = n
      this.$store.commit('mergeStore', {tag: {}})
      this.$socket.emit('get_tag', {id: this.id})
    }
  },
  methods: {
    clickMetric (metric) {
      if (this.$store.state.tag.metrics.includes(metric[0])) {
        this.$toast.error('Выключение метрики')
      } else {
        this.$toast.success('Включение метрики')
      }
      this.$socket.emit('tag_metric', {
        tag_id: this.$store.state.tag.id,
        metric: metric[0],
      })
    },
  },
  async mounted () {
    try {
      this.id = this.$route.params.id
      this.$store.commit('mergeStore', {tag: {}})
      this.$socket.emit('get_tag', {id: this.id})
      this.$socket.emit('get_data', ['users'])
    } catch (e) {
      console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss">
.pagination{
  button.page-link{
    background: black;
    border: black;
  }
}
.hover-pointer:hover{
  cursor: pointer;
}
</style>
